exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ai-coe-js": () => import("./../../../src/pages/ai-coe.js" /* webpackChunkName: "component---src-pages-ai-coe-js" */),
  "component---src-pages-ai-ethics-and-governance-policy-js": () => import("./../../../src/pages/ai-ethics-and-governance-policy.js" /* webpackChunkName: "component---src-pages-ai-ethics-and-governance-policy-js" */),
  "component---src-pages-ai-for-government-js": () => import("./../../../src/pages/ai-for-government.js" /* webpackChunkName: "component---src-pages-ai-for-government-js" */),
  "component---src-pages-ai-for-healthcare-js": () => import("./../../../src/pages/ai-for-healthcare.js" /* webpackChunkName: "component---src-pages-ai-for-healthcare-js" */),
  "component---src-pages-ai-for-retail-js": () => import("./../../../src/pages/ai-for-retail.js" /* webpackChunkName: "component---src-pages-ai-for-retail-js" */),
  "component---src-pages-ai-summit-highlights-big-win-for-techolution-js": () => import("./../../../src/pages/ai-summit-highlights/big-win-for-techolution.js" /* webpackChunkName: "component---src-pages-ai-summit-highlights-big-win-for-techolution-js" */),
  "component---src-pages-ai-summit-highlights-js": () => import("./../../../src/pages/ai-summit-highlights.js" /* webpackChunkName: "component---src-pages-ai-summit-highlights-js" */),
  "component---src-pages-ai-summit-highlights-luv-tulsidas-speech-js": () => import("./../../../src/pages/ai-summit-highlights/luv-tulsidas-speech.js" /* webpackChunkName: "component---src-pages-ai-summit-highlights-luv-tulsidas-speech-js" */),
  "component---src-pages-ai-summit-highlights-our-demos-at-ai-summit-js": () => import("./../../../src/pages/ai-summit-highlights/our-demos-at-ai-summit.js" /* webpackChunkName: "component---src-pages-ai-summit-highlights-our-demos-at-ai-summit-js" */),
  "component---src-pages-ai-summit-highlights-tim-delesio-presentation-js": () => import("./../../../src/pages/ai-summit-highlights/tim-delesio-presentation.js" /* webpackChunkName: "component---src-pages-ai-summit-highlights-tim-delesio-presentation-js" */),
  "component---src-pages-book-demo-js": () => import("./../../../src/pages/book-demo.js" /* webpackChunkName: "component---src-pages-book-demo-js" */),
  "component---src-pages-careers-[id]-js": () => import("./../../../src/pages/careers/[id].js" /* webpackChunkName: "component---src-pages-careers-[id]-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-ggc-framework-for-ai-js": () => import("./../../../src/pages/ggc-framework-for-ai.js" /* webpackChunkName: "component---src-pages-ggc-framework-for-ai-js" */),
  "component---src-pages-google-cloud-js": () => import("./../../../src/pages/google-cloud.js" /* webpackChunkName: "component---src-pages-google-cloud-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-microsoft-azure-js": () => import("./../../../src/pages/microsoft-azure.js" /* webpackChunkName: "component---src-pages-microsoft-azure-js" */),
  "component---src-pages-our-customers-js": () => import("./../../../src/pages/our-customers.js" /* webpackChunkName: "component---src-pages-our-customers-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-techoversity-js": () => import("./../../../src/pages/techoversity.js" /* webpackChunkName: "component---src-pages-techoversity-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-the-dream-team-js": () => import("./../../../src/pages/the-dream-team.js" /* webpackChunkName: "component---src-pages-the-dream-team-js" */),
  "component---src-templates-carousel-template-js": () => import("./../../../src/templates/carouselTemplate.js" /* webpackChunkName: "component---src-templates-carousel-template-js" */),
  "component---src-templates-case-study-template-tsx": () => import("./../../../src/templates/caseStudyTemplate.tsx" /* webpackChunkName: "component---src-templates-case-study-template-tsx" */),
  "component---src-templates-dream-team-member-template-js": () => import("./../../../src/templates/dreamTeamMemberTemplate.js" /* webpackChunkName: "component---src-templates-dream-team-member-template-js" */),
  "component---src-templates-menu-card-template-js": () => import("./../../../src/templates/menuCardTemplate.js" /* webpackChunkName: "component---src-templates-menu-card-template-js" */),
  "component---src-templates-new-blog-template-tsx": () => import("./../../../src/templates/newBlogTemplate.tsx" /* webpackChunkName: "component---src-templates-new-blog-template-tsx" */),
  "component---src-templates-new-menu-card-template-js": () => import("./../../../src/templates/newMenuCardTemplate.js" /* webpackChunkName: "component---src-templates-new-menu-card-template-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */),
  "component---src-templates-white-paper-template-js": () => import("./../../../src/templates/whitePaperTemplate.js" /* webpackChunkName: "component---src-templates-white-paper-template-js" */)
}

